import React from 'react';
import {Tabs, Tab, Container, Image} from 'react-bootstrap';
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter';
import {coy} from 'react-syntax-highlighter/dist/esm/styles/prism'; // You can change this theme


const ToolsPage = () => {
    return (
        <Container className="py-5">
            <h2 className="text-center mb-4">Tools</h2>
            <Tabs defaultActiveKey="midjourney" id="tools-tabs">
                <Tab eventKey="midjourney" title="Midjourney">
                    <div className="mt-3 text-start">
                        <h3>Midjourney</h3>
                        <p>Midjourney is an AI-powered tool that helps you create stunning visuals from text prompts. It
                            leverages advanced machine learning models to generate high-quality images.</p>
                        <p>Midjourney responds to a prompt. With a prompt you tell it what you want with the picture. It
                            is not like an LLM in need for a lot of text. Be short, give the characteristics, no
                            complete stories.</p>
                        <p>This is the prompt I used to generate Quippy. I used ChatGPT to generate this prompt.</p>
                        <SyntaxHighlighter language="text" style={coy} wrapLongLines={true}>
                            {`Create a realistic portrait of Quippy, a friendly and humorous gender neutral robot assistant. Quippy is smart, human size, friendly, with an androgynous appearance, radiating energy and approachability. It has an expressive face, often with a friendly smile. The portrait should focus on their face and shoulders, showcasing their modern, casual style. Quippy wears a trendy t-shirt with its name clearly visible. The background is simple and neutral to keep the focus on Quippy’s lively personality. The overall vibe should be warm, engaging, and slightly playful.`}
                        </SyntaxHighlighter>
                        <p>The result is all over this website.</p>
                        <Image src={"/images/quippy-headshot.png"} alt={"Quippy"}
                               style={{width: '300px', height: 'auto'}}/>
                        <p>One trick of Midjourney that I like is using an existing photo to re-generate the character
                            in another context. You need to copy the url of your image and use the special
                            tag <strong>--cref</strong>. You can make it very strict in following the image
                            using <strong>--cw 100</strong> or very loose using <strong>--cw 0</strong></p>
                        <p>Below is an example.</p>
                        <SyntaxHighlighter language="text" style={coy} wrapLongLines={true}>
                            {`a character dancing with a beautiful girl, both characters look like humans, but have robotic elements --cref https://s.mj.run/_Eghv1nUh1A --cw 25`}
                        </SyntaxHighlighter>
                        <Image src={"/images/jeecee_a_character_dancing_with_a_beautiful_girl_both_characte_2c5c9c2a-fbfc-49cd-9eea-adad89ce7d7a.png"} alt={"Quippy"}
                               style={{width: '300px', height: 'auto'}}/>
                        <p>Besides using character references, you can also use a style reference. What if I want Quippy to look like my picture on a horse. I can use the parameters <strong>--sref</strong> together with a strength parameter <strong>--sw 1000</strong>. You can even combine styles using a space between them.</p>
                        <p>Below is an example.</p>
                        <SyntaxHighlighter language="text" style={coy} wrapLongLines={true}>
                            {`create a character riding a horse --sref https://s.mj.run/e6ZVPJW0-Tc --cref https://s.mj.run/fRWOf4g7pQQ `}
                        </SyntaxHighlighter>
                        <Image src={"/images/jeecee_create_a_character_riding_a_horse_2399b1ac-bc0f-4640-be89-571ef5bd041a.png"} alt={"Quippy"}
                               style={{width: '300px', height: 'auto'}}/>
                        <p>There are a lot more options. I tried to make Quippy drive a Red Bull F1 car. No luck yet, but tips are welcome. I did use the parameter <strong>--ar 3:2</strong> to change the shape of the image.</p>
                        <p>Below is an example.</p>
                        <SyntaxHighlighter language="text" style={coy} wrapLongLines={true}>
                            {`create a character driving a formula 1 car use the colors of red bull f1 --sref https://s.mj.run/c41BCnr9uYI --sw 1000 --cref https://s.mj.run/fRWOf4g7pQQ --cw 100 --ar 3:2`}
                        </SyntaxHighlighter>
                        <Image src={"/images/jeecee_create_a_character_driving_a_formula_1_car_use_the_colo_a8d399b8-c6f8-4104-918c-0ef04b57a98c.png"} alt={"Quippy"}
                               style={{width: '400px', height: 'auto'}}/>
                    </div>
                </Tab>
            </Tabs>
        </Container>
    );
};

export default ToolsPage;