import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Button, Card, Col, Container, Form, InputGroup, Row, Spinner } from 'react-bootstrap';
import { Send } from 'react-bootstrap-icons';
import ReactMarkdown from 'react-markdown';
import './ChatInterface.css';
import { fetchAuthSession } from 'aws-amplify/auth';

const gatewayUrl = 'https://lhpflbm404.execute-api.eu-west-1.amazonaws.com/prod/';

const defaultMessage = [
    {
        "role": "assistant",
        "content": [{
            "text": "My name is Quippy, I can help you store or read knowledge items."
        }]
    }
]

const AgentInterface = () => {
    const [inputValue, setInputValue] = useState('');
    const [sessionId, setSessionId] = useState(uuidv4());
    const [agentMessages, setAgentMessages] = useState(() => {
        const savedMessages = localStorage.getItem('agentMessages');
        return savedMessages ? JSON.parse(savedMessages) : defaultMessage;
    });

    const [loading, setLoading] = useState(false);

    const agentContainerRef = useRef(null);

    useEffect(() => {
        localStorage.setItem('agentMessages', JSON.stringify(agentMessages));
        scrollToBottom();
    }, [agentMessages]);

    const scrollToBottom = () => {
        if (agentContainerRef.current) {
            agentContainerRef.current.scrollTo({
                top: agentContainerRef.current.scrollHeight,
                behavior: 'smooth'
            });
        }
    };

    const callApi = async (updatedMessages) => {
        const apiUrl = gatewayUrl + 'add-knowledge';

        try {
            setLoading(true);
            const { idToken } = (await fetchAuthSession()).tokens ?? {};

            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Authorization': idToken?.toString() ?? '',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ message: inputValue, session_id: sessionId }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            console.log('API response:', data);
            setAgentMessages([...updatedMessages, { role: 'assistant', content: [{ text: data.message }] }]);
        } catch (error) {
            console.error('Error calling API:', error);
            setAgentMessages([...updatedMessages, {
                role: 'assistant',
                content: [{ text: `Error calling API: ${error.message}` }]
            }]);
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleButtonClick = (event) => {
        event.preventDefault();
        if (inputValue.trim() !== '') {
            setAgentMessages(prevMessages => {
                const updatedMessages = [...prevMessages, { role: 'user', content: [{ text: inputValue }] }];
                callApi(updatedMessages);
                return updatedMessages;
            });
            setInputValue('');
        } else {
            alert('Please type a message before sending');
        }
    };

    const handleClearSession = () => {
        localStorage.removeItem('agentMessages');
        setAgentMessages(defaultMessage);
        setSessionId(uuidv4());
    };

    return (
        <Container className="mt-4">
            <Row className="justify-content-center">
                <Col md={6}>
                    <Card>
                        <Card.Header
                            className="bg-primary text-white d-flex justify-content-between align-items-center">
                            <span>Interact with Agent</span>
                            <Button variant="secondary" onClick={handleClearSession}>Clear session</Button>
                        </Card.Header>
                        <Card.Body ref={agentContainerRef} className="chat-container"
                                   style={{ maxHeight: '400px', overflowY: 'auto' }}>
                            {agentMessages.map((msg, index) => (
                                <div key={index} className={`chat-message ${msg.role}`}>
                                    <div className="assistant-avatar">
                                        <img
                                            src={msg.role === 'assistant' ? "/images/quippy-headshot.png" : "/images/jettro.jpg"}
                                            alt={msg.role}
                                            className="rounded-circle mr-3"
                                            style={{ width: '50px', height: '50px' }}
                                        />
                                    </div>
                                    <div className="message-content">
                                        <div className="bg-light p-3 rounded">
                                            <p className="mb-0">
                                                {msg.role === 'assistant' ? (
                                                    <ReactMarkdown>{msg.content[0].text}</ReactMarkdown>
                                                ) : (
                                                    msg.content[0].text
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Card.Body>
                        <Card.Footer>
                            <Form onSubmit={handleButtonClick}>
                                <InputGroup>
                                    <Form.Control
                                        type="text"
                                        placeholder="Type your message..."
                                        value={inputValue}
                                        onChange={handleInputChange}
                                    />
                                    <InputGroup.Text>
                                        <Button className="btn-primary" type="submit" disabled={loading}>
                                            {loading ? <Spinner animation="border" size="sm" /> : <Send />}
                                        </Button>
                                    </InputGroup.Text>
                                </InputGroup>
                            </Form>
                        </Card.Footer>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default AgentInterface;