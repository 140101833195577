import React from 'react';
import HeroSection from "../components/HeroSection";
import FeaturesSection from "../components/FeaturesSection";

const HomePage = () => {
    return (
        <div className="container">
            <HeroSection/>
            <FeaturesSection/>
        </div>
    );
}

export default HomePage;