import React from 'react';
import ChatInterface from "../components/ChatInterface";
import {Authenticator} from "@aws-amplify/ui-react";

const ChatPage = () => {
    return (
        <Authenticator hideSignUp={true} variation={"modal"}>
            {({signOut, user}) => (
                <div className="container">
                    <ChatInterface/>
                </div>
            )}
        </Authenticator>
    );
}

export default ChatPage;