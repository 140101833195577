import React from 'react';
import AgentInterface from "../components/AgentInterface";
import {Authenticator} from "@aws-amplify/ui-react";

const AgentPage = () => {
    return (
        <Authenticator hideSignUp={true} variation={"modal"}>
            {({signOut, user}) => (
                <div className="container">
                    <AgentInterface/>
                </div>
            )}
        </Authenticator>
    );
}

export default AgentPage;