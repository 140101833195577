import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Container, Row, Image } from 'react-bootstrap';

const QuippyFooter = () => {
    return (
        <footer className="bg-dark text-white py-3 mt-auto">
            <Container>
                <Row className="text-center">
                    <Col className="d-flex justify-content-center align-items-center">
                        <p className="mb-0">&copy; 2024 Quippy. All Rights Reserved.</p>
                        <p className="mb-0 mx-3">Sponsored by:</p>
                        <Image src="/images/luminis.png" alt="Luminis" width="150" className="mt-2" />
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}

export default QuippyFooter;