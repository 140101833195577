import React from 'react';
import {Authenticator} from "@aws-amplify/ui-react";

const LoginPage = () => {

    return (
        <div className="container">
            <Authenticator hideSignUp={true} variation={"default"}>
                {({signOut, user}) => (
                    <div className="container">
                        <p>Welcome to the section for authenticated users only. Now you can interact with me.</p>
                        <p>Go to the <a href={'/chat'}>chat page</a>.</p>
                    </div>
                )}
            </Authenticator>
        </div>
    );
}

export default LoginPage;