import {Button, Card, Col, Container, Form, Image, InputGroup, Row, Spinner} from "react-bootstrap";
import {Send} from "react-bootstrap-icons";
import React, {useEffect, useState} from "react";
import {fetchAuthSession} from "aws-amplify/auth";

const gatewayUrl = 'https://lhpflbm404.execute-api.eu-west-1.amazonaws.com/prod/';

const GenImageInterface = () => {
    const [inputValue, setInputValue] = useState('');
    const [foundationModels, setFoundationModels] = useState([]);
    const [selectedModel, setSelectedModel] = useState('amazon.titan-text-express-v1');
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState(''); // State variable for image URL

    useEffect(() => {
        const savedModel = localStorage.getItem('selectedGenImageModel');
        if (savedModel) {
            setSelectedModel(savedModel);
        }
        callFoundationModelsApi();
    }, []);


    const callFoundationModelsApi = async () => {
        const apiUrl = gatewayUrl + 'foundation-models';
        try {
            setLoading(true);
            const {idToken} = (await fetchAuthSession()).tokens ?? {};

            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Authorization': idToken?.toString() ?? '',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({'region': 'eu-west-2', 'outputModality': 'IMAGE'})
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            console.log('API response:', data);
            setFoundationModels(data.models || ['amazon.titan-image-generator-v1'])
        } catch (error) {
            console.error('Error calling API:', error);
            setFoundationModels(['amazon.titan-image-generator-v1'])
        } finally {
            setLoading(false);
        }
    }

    const callApi = async (model) => {
        const apiUrl = gatewayUrl + 'generate-image';

        try {
            setLoading(true);
            const {idToken} = (await fetchAuthSession()).tokens ?? {};

            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Authorization': idToken?.toString() ?? '',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({message: inputValue, model: model}),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            console.log('API response:', response);
            const data = await response.json();
            setImageUrl(data.image_url); // Update image URL state
        } catch (error) {
            console.error('Error calling API:', error);
        } finally {
            setLoading(false);
        }
    };

    // Add handleModelChange function
    const handleModelChange = (event) => {
        const model = event.target.value;
        setSelectedModel(model);
        localStorage.setItem('selectedGenImageModel', model);
    };

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleButtonClick = (event) => {
        event.preventDefault();
        if (inputValue.trim() !== '') {
            callApi(selectedModel);
            setInputValue('');
        } else {
            alert('Please type a message before sending');
        }
    };


    return (
        <Row>
            <Col md={2}>
                <Image src="/images/quippy-headshot.png" alt="Quippy" roundedCircle fluid/>
            </Col>
            <Col md={4}>
                <Card>
                    <Card.Header className="bg-primary text-white d-flex justify-content-between align-items-center">
                        <span>Create a new Image</span>
                    </Card.Header>
                    <Card.Body>
                        <Form onSubmit={handleButtonClick}>
                            <InputGroup className="mb-3">
                                <Form.Select value={selectedModel} onChange={handleModelChange}>
                                    {foundationModels.map(model => (
                                        <option key={model.modelId}
                                                value={model.modelId}>{model.modelName}({model.modelId})</option>
                                    ))}
                                </Form.Select>
                            </InputGroup>
                            <InputGroup>
                                <Form.Control
                                    type="text"
                                    placeholder="Type your message..."
                                    value={inputValue}
                                    onChange={handleInputChange}
                                />
                                <InputGroup.Text>
                                    <Button className="btn-primary" type="submit" disabled={loading}>
                                        {loading ? <Spinner animation="border" size="sm"/> : <Send/>}
                                    </Button>
                                </InputGroup.Text>
                            </InputGroup>
                        </Form>
                    </Card.Body>
                </Card>
            </Col>
            <Col md={6}>
                <h2>Preview Image</h2>
                {imageUrl && <img src={imageUrl} alt="Generated" className="img-fluid"/>} {/* Display the image */}
            </Col>
        </Row>
    )
}

export default GenImageInterface;
