import React, {useEffect, useState} from 'react';
import {Spinner, Card, Row, Col} from 'react-bootstrap';
import {fetchAuthSession} from "aws-amplify/auth";

const gatewayUrl = 'https://lhpflbm404.execute-api.eu-west-1.amazonaws.com/prod/';

const ListPreviousImages = () => {
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const {idToken} = (await fetchAuthSession()).tokens ?? {};

                const response = await fetch(gatewayUrl + "list-images", {
                    method: 'GET',
                    headers: {
                        'Authorization': idToken?.toString() ?? '',
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                setImages(data.images);
            } catch (error) {
                console.error('Error fetching images:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchImages();
    }, []);

    if (loading) {
        return <Spinner animation="border"/>;
    }

    return (
        <>
            {images.map((image, index) => (
                <Card>
                    <Card.Img variant="top" src={image.url} />
                    <Card.Body>
                        <Card.Text>{image.metadata?.message}</Card.Text>
                    </Card.Body>
                </Card>
            ))}
        </>
    );
};

export default ListPreviousImages;