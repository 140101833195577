// Create a new file `src/components/HeroSection.js`
import React from 'react';
import {Image} from "react-bootstrap";

const HeroSection = () => {
  return (
    <section className="hero">
      <div className="container">
        <Image src="/images/quippy-headshot.png" alt="Quippy Image" roundedCircle style={{ width: '200px', height: '200px' }}/>
        <h1>Meet Quippy: Your Clever and Friendly Assistant</h1>
        <p>Always ready to help with a dash of humor.</p>
        <div className="mt-4">
          <img src="/images/quippy-logo.png" alt="Quippy Logo" style={{ width: '419px' }} />
        </div>
      </div>
    </section>
  );
};

export default HeroSection;