import React from 'react';
import ChatInterface from "../components/ChatInterface";
import {Authenticator} from "@aws-amplify/ui-react";
import GenImageInterface from "../components/GenImageInterface";
import ListPreviousImages from "../components/ListPreviousImages";
import {Col, Container, Row} from "react-bootstrap";

const ChatPage = () => {
    return (
        <Authenticator hideSignUp={true} variation={"modal"}>
            {({signOut, user}) => (
                <Container>
                    <Row>
                        <Col md={10}>
                            <GenImageInterface/>
                        </Col>
                        <Col md={2}>
                            <ListPreviousImages/>
                        </Col>
                    </Row>
                </Container>
            )}
        </Authenticator>
    )
        ;
}

export default ChatPage;