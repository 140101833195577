import React, {useEffect, useState} from 'react';
import {Nav, Navbar, OverlayTrigger, Tooltip} from "react-bootstrap";
import {useAuthenticator} from "@aws-amplify/ui-react";
import {fetchAuthSession} from "aws-amplify/auth";

const QuippyNavbar = () => {
    const {user, signOut} = useAuthenticator((context) => [context.user]);
    const [userInfo, setUserInfo] = useState(null);

    useEffect(() => {
        const getUserInfo = async () => {
            if (user) {
                const {idToken} = (await fetchAuthSession()).tokens ?? {};
                if (idToken) {
                    setUserInfo(idToken.payload);
                }
            }
        };
        getUserInfo();
    }, [user]);


    return (
        <Navbar bg="light" expand="lg">
            <Navbar.Brand href="/">
                <img src="/images/quippy-logo.png" alt="Quippy Logo" className="img-fluid" style={{height: '60px'}}/>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto">
                    <Nav.Link href="/">Home</Nav.Link>
                    {user ? (
                        <>
                            <Nav.Link href="/chat">Chat</Nav.Link>
                            <Nav.Link href="/gen-image">Generate Image</Nav.Link>
                            <Nav.Link href="/agent">Agent</Nav.Link>
                        </>
                    ) : (
                        <></>
                    )}
                    <Nav.Link href="/tools">Tools</Nav.Link>
                    <Nav.Link href="/about">About</Nav.Link>
                    {user ? (
                        <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip id="username-tooltip">Logged in as {userInfo?.email}</Tooltip>}
                        >
                            <Nav.Link href="#"><i className="bi bi-person"></i></Nav.Link>
                        </OverlayTrigger>
                    ) : (
                        <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip id="username-tooltip">Log in</Tooltip>}
                        >
                            <Nav.Link href="/login"><i className="bi bi-door-open"></i></Nav.Link>
                        </OverlayTrigger>
                    )}
                    {user ? (
                        <Nav.Link onClick={signOut}><i className="bi bi-door-closed"></i></Nav.Link>
                    ) : (<></>)}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}

export default QuippyNavbar;
