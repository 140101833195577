// Create a new file `src/components/FeaturesSection.js`
import React from 'react';

const FeaturesSection = () => {
  return (
    <section className="features">
      <div className="container">
        <div className="row text-center">
          <div className="col-md-4 feature">
            <h3>Interactive Assistance</h3>
            <p>Quippy helps you manage your day with ease.</p>
          </div>
          <div className="col-md-4 feature">
            <h3>Customization</h3>
            <p>Personalize Quippy to fit your style.</p>
          </div>
          <div className="col-md-4 feature">
            <h3>Tools Integration</h3>
            <p>Connect with your favorite apps seamlessly.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;