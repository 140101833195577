import React from 'react';
import {Route, Routes} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import QuippyNavbar from "./components/QuippyNavbar";
import QuippyFooter from "./components/QuippyFooter";
import ChatPage from "./pages/ChatPage";
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";

import {Authenticator} from "@aws-amplify/ui-react";
import '@aws-amplify/ui-react/styles.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import LoginPage from "./pages/LoginPage";
import GenImagePage from "./pages/GenImagePage";
import ToolsPage from "./pages/ToolsPage";
import AgentPage from "./pages/AgentPage";

export default function App() {
    return (
        <Authenticator.Provider>
            <>
                <QuippyNavbar/>
                <Routes>
                    <Route path="/" element={<HomePage/>}/>
                    <Route path="/chat" element={<ChatPage/>}/>
                    <Route path="/gen-image" element={<GenImagePage/>}/>
                    <Route path="/agent" element={<AgentPage/>}/>
                    <Route path="/tools" element={<ToolsPage/>}/>
                    <Route path="/about" element={<AboutPage/>}/>
                    <Route path="/login" element={<LoginPage/>}/>
                </Routes>
                <QuippyFooter/>
            </>
        </Authenticator.Provider>
    );
}